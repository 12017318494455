import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader


//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

import $ from 'jquery';

function ManageStudentContent() {

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgrouptitle={
        margin: '0px',
        fontWeight: 700,
        backgroundColor: 'rgba(9,9,9,0.2)'
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'small'
    };

    const txtblodcenter={
        fontWeight: '700',
        fontSize: 'medium',
        textAlign: 'center'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.4)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconkey={
        color: '#d49b00',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrophy={
        color: '#0004d4',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrash={
        color: '#d40000',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const div70={
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700'
    };

    const txtgrey={
        color: '#787878',
        fontWeight: '700'
    };

    const picstyle={
        width: '70px'
    };
    
    const picstylelarge={
        width: '300px'
    };

    // For Modal Check
    let [modalid,setModalid]=useState(1);
    // For Modal Check

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [search,setSearch]=useState("");

    let [batchlistarr, setBatchlistarr]=useState([]);
    let [batchslno, setBatchslno]=useState(0);

    let [name,setName]=useState("");
    let [id,setId]=useState("");
    let [status,setStatus]=useState("");
    let [batchnm,setBatchnm]=useState("");

    let [changestatus, setChangestatus]=useState("");
    let [newpassword, setNewpassword]=useState("");
    let [ispassworderr, setIspassworderr]=useState(false);
    
    let [count,setCount]=useState("");

    // let [pageno,setPageno]=useState("");
    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");
    let [studlistarr, setStudlistarr]=useState([]);
    let [loader,setLoader]=useState(false);
    
    let [pic,setPic]=useState("");

    let [filetype,setFiletype]=useState("");

    let [email,setEmail]=useState("");
    //let [limit,setLimit]=useState(3);

    let limit = 50;

    async function getStudlist() {
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("search",search);
        fd.append("limit",limit);
        //alert(pageno);
        var resp=await axios.post("https://safalata.in/safalatalara/api/getstudlist",fd);
        var data=resp.data;
        setStudlistarr(data.obj);
        setCount(data.datacount);

        const total=data.datacount;
        setPageCount(Math.ceil(total/limit));
        
        setLoader(false);
    }

    async function getBatchlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/newbatchlist");
        var data=resp.data;
        setBatchlistarr(data);
    }

    const handlePageClick = async (event) => {
        let page=event.selected+1;
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",page);
        fd.append("search",search);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.in/safalatalara/api/getstudlist",fd);
        var data=resp.data;
        setStudlistarr(data.obj);
        setCount(data.datacount);
        setLoader(false);
    };



    useEffect(()=>{
        getStudlist();
        // setPageno(1);
        getBatchlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">MANAGE STUDENT</h2>

            <div className='container' style={batchbg}>
                <div className="mt-4 input-group" style={div70}>
                    <input type="text" className='form-control' onChange={ async (ev)=>{
                        if (ev.target.value.length>0) {
                            setSearch(ev.target.value);
                            setIsnewbtn(false);
                        } else if (ev.target.value=="") {
                            setIsnewbtn(true);
                            setLoader(true);
                            var fd=new FormData();
                            fd.append("pageno",pageno);
                            fd.append("search","");
                            fd.append("limit",limit);
                            var resp=await axios.post("https://safalata.in/safalatalara/api/getstudlist",fd);
                            var data=resp.data;
                            setStudlistarr(data.obj);
                            setCount(data.datacount);

                            const total=data.datacount;

                            setPageCount(Math.ceil(total/limit));
                            setLoader(false);
                        }
                    }} placeholder='Search Student Name OR Enroll ID'/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getStudlist}>SEARCH</button>
                    </span>
                </div>

            </div>

            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    STUDENT LIST - TOTAL NO OF STUDENTS ( {count} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod} className='text-center'>Identity</th>
                                    <th style={txtblod} className='text-center'>Document</th>
                                    <th style={txtblod}>Name</th>
                                    <th style={txtblod}>DOB</th>
                                    <th style={txtblod}>Mobile</th>
                                    <th style={txtblod}>City</th>
                                    <th style={txtblod}>Enroll</th>
                                    <th style={txtblod}>Education</th>
                                    <th style={txtblod}>Batch</th>
                                    {/* <th style={txtblod} className='text-center'>Enroll Date</th> */}
                                    {/* <th style={txtblod}>MA</th>
                                    <th style={txtblod}>PGDT</th> */}
                                    <th style={txtblod}>Status</th>
                                    <th style={txtblod} className='text-center'>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {studlistarr.map((e)=>
                                <tr id={"tr_"+e.id} key={e.id}>
                                    {/* <td className='text-center'><Link to="" className='btn-default' onClick={()=>{
                                        openModal();
                                        setModalid(4);
                                        setPic(e.pic);                                        
                                        }}><img src={"https://safalata.in/safalatalara/uploadpic/"+e.pic} style={picstyle}/></Link></td> */}
                                    
                                    <td className={e.pic_approve==0? 'pic_approve_class text-center bg-danger':'pic_approve_class text-center'} >
                                    
                                        
                                        {e.pic!=null && e.pic!='null' && e.pic!=''?
                                        <Link to="#" className='btn-default' onClick={()=>{
                                            openModal();
                                            setModalid(4);
                                            setPic(e.pic); 
                                            setId(e.id);
                                            setFiletype("photo");          
                                            setEmail(e.email);                         
                                            }}>
                                        <img src={"https://safalata.in/safalatalara/uploadpic/"+e.pic} style={picstyle}/>
                                        </Link>
                                        :
                                        <Link to="#" className='btn-default' onClick={()=>{
                                            openModal();
                                            setModalid(4);
                                            setPic("default.png"); 
                                            setId(e.id);
                                            setFiletype("photo");    
                                            setEmail(e.email);                           
                                            }}>
                                        <img src={"https://safalata.in/safalatalara/uploadpic/default.png"} style={picstyle}/>
                                        </Link>
                                        }
                                        
                                    
                                    
                                    </td>
                                    
                                    
                                    <td className={e.document_approve==0? 'doc_approve_class text-center bg-danger':'doc_approve_class text-center'}>
                                        
                                        {e.document!=null && e.document!='null' && e.document!=''?
                                        <Link to="#" className='btn-default' onClick={()=>{
                                            openModal();
                                            setModalid(4);
                                            setPic(e.document);   
                                            setId(e.id); 
                                            setFiletype("document");     
                                            setEmail(e.email);                               
                                            }}>
                                        <img src={"https://safalata.in/safalatalara/uploadpic/"+e.document} style={picstyle}/>
                                        </Link>
                                    :
                                        <Link to="#" className='btn-default' onClick={()=>{
                                        openModal();
                                        setModalid(4);
                                        setPic("nodocument.png");   
                                        setId(e.id);           
                                        setFiletype("document");      
                                        setEmail(e.email);                    
                                        }}>
                                        <img src={"https://safalata.in/safalatalara/uploadpic/nodocument.png"} style={picstyle}/>
                                        
                                        </Link>
                                        }
                                   
                                    </td>
                                    
                                    <td style={txtblod}>{e.name}</td>
                                    <td>{dateFormat(e.dob, "dd-mm-yyyy")}</td>
                                    <td>{e.phone}</td>
                                    <td>{e.city}</td>
                                    <td style={txtblod}>ID: {e.enroll_id>0?e.enroll_id:<button className='btn-default' onClick={async ()=>{
                                        if(window.confirm('Sure, Confirm Enrollment?')) {
                                            var fd=new FormData();
                                            fd.append("id",e.id);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/createenrollid",fd);
                                            var data=resp.data;
                                            getStudlist();
                                        }
                                    }}><i className='fa fa-screwdriver' style={iconscrew}></i></button>}<br/>
                                    DATE: {e.enroll_date?dateFormat(e.enroll_date, "dd-mm-yyyy"):''}</td>
                                    <td>MA: {e.ma==1?'Completed':e.ma==2?'Pursuring':'Others'}<br />
                                    PGDT: {e.pgdt==1?'Completed':e.pgdt==2?'Pursuring':'Others'}</td>
                                    <td style={txtblod}>{e.batch_name} <Link to="" className='btn-default' onClick={async ()=>{
                                        openModal();
                                        setModalid(1);
                                        setId(e.id);
                                        setName(e.name);
                                        setBatchnm(e.batch_name);
                                    }}><i className='fas fa-exchange-alt' style={iconscrew}></i></Link></td>
                                    
                                    {/* <td className='text-center'>{e.enroll_date?dateFormat(e.enroll_date, "dd-mm-yyyy"):''}</td> */}
                                    {/* <td>{e.ma==1?'Completed':e.ma==2?'Pursuring':e.ma==3?'Others':''}<br/>{e.mapassingyr}</td>
                                    <td>{e.pgdt==1?'Completed':e.pgdt==2?'Pursuring':e.pgdt==3?'Others':''}<br/>{e.pgdtpassingyr}</td> */}
                                    {e.status==0?<td style={txtgrey}>Pending</td>:''}
                                    {e.status==1?<td style={txtgreen}>Active</td>:''}
                                    {e.status==2?<td style={txtred}>Inactive</td>:''}
                                    {e.status==3?<td style={txtred}>Blocked</td>:''}
                                    
                                    <td className='text-center'>
                                        <Link to="" className='btn-default' onClick={()=>{
                                        openModal();
                                        setModalid(2);
                                        setId(e.id);
                                        setName(e.name);
                                        setStatus(e.status);
                                        }}><i className="fas fa-edit" style={iconedit}></i></Link>&nbsp;&nbsp;
                                        <Link to="" className='btn-default' onClick={()=>{
                                        openModal();
                                        setModalid(3);
                                        setId(e.id);
                                        setName(e.name);
                                        }}><i className="fas fa-key" style={iconkey}></i></Link>&nbsp;&nbsp;
                                        <Link to="" className='btn-default' onClick={async ()=>{
                                        if(window.confirm("Sure, Allow for Success Story??")) {
                                            var fd=new FormData();
                                            // fd.append("id",e.id);
                                            fd.append('enroll_id',e.enroll_id);
                                            // fd.append("successstory",1);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/updatestudstatus",fd);
                                            var data=resp.data;
                                            getStudlist();
                                        }
                                        }}><i className="fas fa-trophy" style={icontrophy}></i></Link>&nbsp;&nbsp;
                                        <Link to="" className='btn-default' onClick={async ()=>{
                                        if(window.confirm("Sure, Delete Student from SAFALATA??")) {
                                            var fd=new FormData();
                                            fd.append("uid",e.id);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/dashboarddatadelete",fd);
                                            var data=resp.data;
                                            getStudlist();
                                        }
                                        }}><i className="fas fa-trash" style={icontrash}></i></Link>
                                    </td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            // pageCount={20}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            // disabledClassName={"pagination__link--disabled"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div>

            

        </div>


        {/* For Modal */}
        {modalid==1?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT BATCH ALTERATION:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT BATCH - {batchnm}
            </div>
            
            <div className="form-group" style={formgroup}>
                <label>Change Batch - </label>
                    <select value={batchslno} className="form-control" onChange={(ev)=>{
                        setBatchslno(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                        } else {
                            setIsnewbtn(true);
                        }
                    }} >
                        <option value="">-------------------------Select Batch-------------------------</option>
                        {batchlistarr.map((e)=>
                            <option value={e.batch_slno} key={e.batch_slno}>{e.batch_name}</option>
                        )}
                    </select>
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("id",id);
                fd.append("batchslno",batchslno);
                var resp=await axios.post("https://safalata.in/safalatalara/api/updatestudstatus",fd);
                var data=resp.data;
                setIsOpen(false);
                //batchwisefetchlist();
                getStudlist();                
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}
        {modalid==2?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT STATUS:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT STATUS - {status==0?<span style={txtgrey}>PENDING</span>:''}{status==1?<span style={txtgreen}>ACTIVE</span>:''}{status==2?<span style={txtred}>INACTIVE</span>:''}
            </div>
            
            <div className="form-group" style={formgroup}>
                <select value={changestatus} className="form-control" onChange={(ev)=>{
                    setChangestatus(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }}>
                    <option value="">----Select Status----</option>
                    <option value="0">Pending</option>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                    <option value="3">Blocked</option>
                </select>
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("id",id);
                fd.append("status",changestatus);
                var resp=await axios.post("https://safalata.in/safalatalara/api/updatestudstatus",fd);
                var data=resp.data;
                setIsOpen(false);       
                getStudlist()         
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}
        {modalid==3?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT PASSWORD CHANGE:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setNewpassword(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                    setIspassworderr(false);
                }} placeholder='Atleast 6 characters password'/>
                {ispassworderr?<div className='text text-danger'>Atleast 6 length password required!</div>:''}
                    
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var flag=1;

                if (newpassword.length<6) {
                    setIspassworderr(true);
                    flag=0;
                }

                if (flag==1) {
                    var fd=new FormData();
                    fd.append("id",id);
                    fd.append("newpassword",newpassword);
                    var resp=await axios.post("https://safalata.in/safalatalara/api/updatestudstatus",fd);
                    var data=resp.data;
                    setIsOpen(false);       
                    getStudlist();
                }
                         
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}

        {modalid==4?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT PIC:::
            </div>
            <div className='form-group' style={formgroup}>
            <img src={"https://safalata.in/safalatalara/uploadpic/"+pic} style={picstylelarge}/>
            </div>

            {/* <div className='row form-group'>
            <div className='col-6 text-center'>
                <Link className='text-primary' to="" onClick={async ()=>{
                    var fd=new FormData();
                    fd.append("filetype",filetype);
                    fd.append("id",id);
                    fd.append("email",email);
                    var resp=await axios.post("https://safalata.in/safalatalara/api/sendchangerequest",fd);
                    var data=resp.data;

                    if (filetype=='photo') {
                        $("#tr_"+id).find(".pic_approve_class").addClass('bg-danger');
                    } else {
                        $("#tr_"+id).find(".doc_approve_class").addClass('bg-danger');
                    }
                    

                }}>Change Request</Link>
            </div>
            <div className='col-6 text-center'>
                <Link className='text-primary' to="" onClick={async ()=>{
                    var fd=new FormData();
                    fd.append("filetype",filetype);
                    fd.append("id",id);
                    var resp=await axios.post("https://safalata.in/safalatalara/api/sendchangeapproved",fd);
                    var data=resp.data;

                    if (filetype=='photo') {
                        $("#tr_"+id).find(".pic_approve_class").removeClass('bg-danger');
                    } else {
                        $("#tr_"+id).find(".doc_approve_class").removeClass('bg-danger');
                    }
                    

                }}>Response Approved</Link>
            </div>
            </div> */}
            
            
            <div className="form-group text-center" style={formgroup}>
            <button onClick={()=>{
                    setIsOpen(false);
            }} className="btn btn-primary">Close</button>
            </div>
            
        </Modal>
        :''}

        {/* FOr Modal */}
        </>
    );

}

export default ManageStudentContent;