import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';

function Nav() {

    // let [adminname,setAdminname]=useState('');

    // async function getadminToken() {
    //     console.log(localStorage.getItem("admintokenID"));
        
    //     var tokenval=localStorage.getItem("admintokenID");
    //     if (tokenval!=null) {
    //       var resp=await fetch("https://safalata.in/safalatalara/api/admintoken", {
    //         headers: {
    //           "Content-Type":"application/json",
    //           "Authorization":"Bearer "+tokenval
    //         }
    //       });
    //       var data=await resp.json();
          
    //       setAdminname(data.name);
          
    //     } else {
    //       window.location.href="/login";
    //     }
  
    // }

    // useEffect(()=>{
    //     getadminToken();
    // },[])

    if (localStorage.getItem("admintokenID")==null) {
        window.location.href="/login";
    }

    return(
        <>

        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
            {/* <!-- Navbar Brand--> */}
            <Link className="navbar-brand ps-3" to="/">SAFALATA ADMIN</Link>
            {/* <!-- Sidebar Toggle--> */}
            <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" to="#!" onClick={(event)=>{
                event.preventDefault();
                document.body.classList.toggle('sb-sidenav-toggled');
                localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
            }} ><i class="fas fa-align-left"></i></button>
            {/* <i className="fas fa-bars"></i> */}
            {/* <!-- Navbar Search--> */}


            {/* <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                <div className="input-group">
                    <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                    <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
                </div>
            </form> */}


            {/* <!-- Navbar--> */}
            <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" id="navbarDropdown" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></Link>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        {/* <li><Link className="dropdown-item" to="#!">{adminname}</Link></li> */}
                        <li><Link className="dropdown-item" to="#!">{localStorage.getItem("adminname")}</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link className="dropdown-item" to="/changepassword">Change Password</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link className="dropdown-item" to="#" onClick={()=>{
                            localStorage.removeItem("admintokenID");
                            localStorage.removeItem("adminname");
                            window.location.href="/login";
                        }}>Logout</Link></li>
                    </ul>
                </li>
            </ul>

            <Link className="navbar-brand ps-3" to="#"  onClick={()=>{
                          localStorage.removeItem("admintokenID");
                          localStorage.removeItem("adminname");
                          window.location.href="/login";
                        }}>Logout</Link>
        </nav>

        </>
    );
}

export default Nav;