import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

// import $ from "jquery";


// CONVERT JS TO PDF
import { useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// CONVERT JS TO PDF


function PracticeExamReportingContent() {

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtblue={
        color: '#0e007a',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const selectright={
        paddingRight: '10px'
    };

    let [practicescorelistarr, setPracticescorelistarr]=useState([]);
    let [startdate, setStartdate]=useState("");
    let [enddate, setEnddate]=useState("");

    let [batchlistarr,setBatchlistarr]=useState([]);
    let [studlistarr, setStudlistarr]=useState([]);
    let [studbatchid,setStudbatchid]=useState("");
    let [studscorelistarr,setStudscorelistarr]=useState([]);
    let [scoremonthyear,setScoremonthyear]=useState("");
    
    let [studname,setStudname]=useState("");
    let [studenrollid,setStudenrollid]=useState("");
    let [studbatchname,setStudbatchname]=useState("");
    let [studmonthyear,setStudmonthyear]=useState("");

    let [loader,setLoader]=useState(false);

    let [offset,setOffset]=useState(0);
    let [enrolllistarr,setEnrolllistarr]=useState([]);
    let [enrolllist,setEnrolllist]=useState("");

    let [noofstudent,setNoofstudent]=useState("");

    //let [slno,setSlno]=useState(0);
    let slno=0;

    // CONVERT JS TO PDF
    let [ispdfbtn,setIspdfbtn]=useState(true);
    // CONVERT JS TO PDF
    

    async function getScoremonthyearlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/activebatchlist");
        var data=resp.data;
        setPracticescorelistarr(data);
    }

    // async function getPracticeexambatchlist() {
    //     var fd=new FormData();
    //     fd.append("practicesetslno",practiceexamsetid);
    //     var resp=await axios.post("https://safalata.in/safalatalara/api/practiceexambatchlist",fd);
    //     var data=resp.data;
    //     setExambatchlistarr(data);
    // }

    useEffect(()=>{
        getScoremonthyearlist();
        // getBatchlist();
    },[])



    // CONVERT JS TO PDF
    const inputRef = useRef(null);
        const printDocument = () => {
            html2canvas(inputRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            pdf.addImage(imgData, "JPEG", -15, -10, 0, 0);
            pdf.save("download.pdf");
        });
    };
    // CONVERT JS TO PDF


    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">PRACTICE EXAM REPORTING DOWNLOAD EXCEL SHEET</h2>

            <div className='container' style={batchbg}>
                <div className='row'>
                    
                    
                    <div className="mt-4 col-2">
                        <div style={selectright}>
                            <input className='form-control' type='date' onChange={(e)=>{
                                setStartdate(e.target.value);
                            }} />
                        </div>
                    </div>
                    <div className="mt-4 col-2">
                        <div style={selectright}>
                            <input className='form-control' type='date' onChange={(e)=>{
                                setEnddate(e.target.value);
                            }} />
                        </div>
                    </div>

                    <div className="mt-4 col-3">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                setStudbatchid(ev.target.value);
                                if (ev.target.value.length>0) {
                                    var fd=new FormData();
                                    fd.append('batchid',ev.target.value);
                                    fd.append('startdate',startdate);
                                    fd.append('noofstudent',noofstudent);
                                    // alert(ev.target.value+' , '+startdate)
                                    var resp=await axios.post("https://safalata.in/safalatalara/api/studlist_for_practiceexamreport",fd);
                                    var data=resp.data;
                                    setOffset(data.offset);
                                    setEnrolllistarr(data.objenroll);

                                    // alert(data.offset+' :: '+data.objenroll[0].range_enroll_id+' - '+data.objenroll[0].range_enroll_id2);
                                    // setStudlistarr(data);

                                    // setStudscorelistarr([]);
                                    // setStudname("");
                                    // setStudenrollid("");
                                    // setStudbatchname("");
                                    // setStudmonthyear("");
                                    // setIspdfbtn(true);
                                } else {
                                    // setStudlistarr([]);

                                    // setStudscorelistarr([]);
                                    // setStudname("");
                                    // setStudenrollid("");
                                    // setStudbatchname("");
                                    // setStudmonthyear("");
                                    // setIspdfbtn(true);
                                }

                            }}>
                            <option value="">---------SELECT BATCH---------</option>

                            {practicescorelistarr.map((e)=>
                                <option key={e.batch_slno} value={e.batch_slno}>{e.batch_name}</option>                             
                            )}

                            </select>
                        </div>
                    </div>

                    <div className="mt-4 col-2">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                setNoofstudent(ev.target.value);
                                if (ev.target.value.length>0) {
                                    var fd=new FormData();
                                    fd.append('batchid',studbatchid);
                                    fd.append('startdate',startdate);
                                    fd.append('noofstudent',ev.target.value);
                                    var resp=await axios.post("https://safalata.in/safalatalara/api/studlist_for_practiceexamreport",fd);
                                    var data=resp.data;
                                    setOffset(data.offset);
                                    setEnrolllistarr(data.objenroll);
                                    console.log("enrollrage <<========>> ",data.objenroll);
                                    console.log("usercount <<========>> ",data.usercount);
                                    console.log("page <<========>> ",data.page);
                                }

                            }}>
                            <option value="">---------SET NO OF STUDENT---------</option>
                            <option value='400'>400</option>
                            <option value='300'>300</option>
                            <option value='200'>200</option>
                            <option value='180'>180</option>                             
                            <option value='160'>160</option>
                            <option value='140'>140</option>
                            <option value='120'>120</option>
                            <option value='100'>100</option>
                            <option value='90'>90</option>
                            <option value='80'>80</option>
                            <option value='75'>75</option>
                            <option value='70'>70</option>
                            <option value='50'>50</option>
                            <option value='40'>40</option>
                            <option value='25'>25</option>
                            </select>
                        </div>
                    </div>

                    {/* {offset>0? */}
                    <div className="mt-4 col-3">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                // setStudbatchid(ev.target.value);
                                if (ev.target.value.length>0) {
                                    setEnrolllist(ev.target.value);
                                }

                            }}>
                            <option value="">---------Enroll List---------</option>

                            {enrolllistarr.map((e)=>
                                <option key={e.range_enroll_id} value={e.range_enroll_id+'-'+e.range_enroll_id2}>{e.range_enroll_id+'-'+e.range_enroll_id2}</option>                             
                            )}

                            </select>
                        </div>
                    </div>
                    {/* : ''
                    } */}

                    <div className="mt-4 col-3">
                        <span className="input-group-btn">
                            <button className="btn btn-info" type="button" onClick={()=>{
                                if (offset==0) {
                                    window.open("https://safalata.in/safalatalara/export/"+studbatchid+"/"+btoa(startdate)+"/"+btoa(enddate),"_blank");
                                    // window.open("http://localhost/safalatalara/export/"+studbatchid+"/"+btoa(startdate)+"/"+btoa(enddate),"_blank");
                                } else {
                                    window.open("https://safalata.in/safalatalara/export/"+studbatchid+"/"+btoa(startdate)+"/"+btoa(enddate)+"/"+enrolllist,"_blank");
                                }
                            }}>Download Worksheet</button>
                        </span>
                    </div>
                </div>
                

            </div>

            


        </div>


        </>
    );

}

export default PracticeExamReportingContent;